<template>
  <div class="mt-4">
    <v-card-text>
      <v-row v-if="!is_second_step">
        <v-col cols="12" md="4">
          <v-select
            :items="table_items"
            item-value="table_no"
            item-text="table_no"
            label="Table #"
            required
            :rules="rules.combobox_rule"
            v-model="table_no"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="type_of_order"
            class="mb-4"
            dense
            outlined
            label="Type"
            :items="['WALK-IN','FOOD PANDA','GRAB FOOD']"
            :rules="rules.combobox_rule"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-if="$router.currentRoute.name==='new-order-dine-in'"
            v-model="name_of_customer"
            outlined
            label="Name of Customer"
            placeholder="JUAN DELA CRUZ"
            :rules="rules.non_empty_field_rule"
            dense
            required
          ></v-text-field>
          <v-text-field
            v-else
            v-model="name_of_customer"
            outlined
            label="Name of Customer"
            placeholder="JUAN DELA CRUZ"
            :rules="rules.non_empty_field_rule"
            dense
            required
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      table_no: '',
      type_of_order: '',
      name_of_customer: '',
      table_items: [],
    }
  }
  export default {
    props: {
      is_second_step: Boolean,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    watch: {
      table_no: function () {
        switch (this.$router.currentRoute.name) {
          case 'new-order-dine-in':
            this.$emit('data', {
              table_no: this.table_no,
              type_of_order: this.type_of_order,
              name_of_customer: this.name_of_customer,
            })
            break
          case 'add-new-order-dine-in':
            this.name_of_customer = this.table_items[this.table_items.map(function(x) {return x.table_no; }).indexOf(this.table_no)].name_of_customer;
            this.type_of_order = this.table_items[this.table_items.map(function(x) {return x.table_no; }).indexOf(this.table_no)].type_of_order;
            this.$emit('data', {
              table_no: this.table_no,
              type_of_order: this.type_of_order,
              name_of_customer:this.name_of_customer ,
            })
            break
        }
      },
      name_of_customer: function () {
        this.$emit('data', {
          table_no: this.table_no,
          type_of_order: this.type_of_order,
          name_of_customer: this.name_of_customer,
        })
      },
    },
    mounted() {
      switch (this.$router.currentRoute.name) {
        case 'new-order-dine-in':
          this.get_available_table({
            branch_id: this.branch_id,
          })
            .then(response => {
              this.table_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
          break
        case 'add-new-order-dine-in':
          this.get_available_table_not_yet_paid({
            branch_id: this.branch_id,
          })
            .then(response => {
              this.table_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
          break
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['user_id', 'branch_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('table', ['get_available_table','get_available_table_not_yet_paid']),
    },
  }
</script>
