<template>
  <div class="mt-4">
    <h2>
      Menu Data | Total: P {{ total_amount }} pesos only
    </h2>
    <v-data-table dense
                  :headers="headers2"
                  :items="menus_data"
                  disable-pagination
                  hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            {{ item.menu.title}}
          </td>
          <td>
            {{ formatPrice(item.amount) }}
          </td>
          <td>
            {{ item.quantity}}
          </td>
          <td>
            {{ formatPrice(item.total) }}
          </td>
          <td class="text-center" v-if="is_can_delete">
            <v-icon
              v-if="!is_deleting"
              class="mr-2"
              color="error"
              @click="delete_menus_order(item.id>0?
                    menus_data.map(function(x) {return x.id; }).indexOf(item.id)
                    :menus_data.map(function(x) {return x.done; }).indexOf(item.done),item.id>0?item.id:0)"
            >
              {{icons.mdiDelete}}
            </v-icon>
            <v-progress-circular color="info" indeterminate
                                 v-else></v-progress-circular>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import {
    mdiAccountSearchOutline,
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiDelete
  } from "@mdi/js";
  import {mapActions} from "vuex";

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      total_amount: '0.00',

      is_deleting: false,
      headers2: [
        {text: 'MENU', value: 'date_of_deposit', sortable: false},
        {text: 'AMOUNT', value: 'amount', sortable: false},
        {text: 'QUANTITY', value: 'amount', sortable: false},
        {text: 'TOTAL', value: 'amount', sortable: false},
        {text: 'Action', value: 'action', sortable: false},
      ],
    }
  }
  export default {
    props: {
      menus_data: Array,
      is_can_delete: Boolean,
      type_of_order: String,
    },
    data() {
      return initialState()
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiDelete,
        },
      }
    },
    mounted() {
      this.over_all_total()
    },
    watch: {
      menus_data: function () {
        this.$emit('data', {
          menus_data: this.menus_data,
        })
        this.over_all_total()
      },
    },
    methods: {
      ...mapActions('table_order', ['delete_table_order']),
      total_per_menu(item, orderType) {
        var tott = 0;
        if (orderType === 'WALK-IN') {
          tott = item.quantity * item.menu.amount
        } else if (orderType === 'FOOD PANDA') {
          tott = item.quantity * item.menu.amount_food_panda
        } else if (orderType === 'GRAB FOOD') {
          tott = item.quantity * item.menu.amount_grab
        }
        return tott;
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      delete_menus_order(index, id) {
        this.menus_data.splice(index, 1)
        if (id > 0) {
          this.delete_table_order({
            id: id,
          })
        }
      },
      over_all_total() {
        this.total_amount = '0.00'
        var tot = 0
        if (this.menus_data != null) {
          this.menus_data.forEach(function (item) {
            tot += parseFloat(item.total)
          })
        }
        this.total_amount = this.formatPrice(tot)
      },
    }
  }
</script>
