<template>
  <div class="mt-4">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Search Menu"
            placeholder="Press Enter to Search"
            required
            v-model="search_menu"
          ></v-text-field>
          <v-data-table dense
                        :headers="headers"
                        :items="order_items"
                        :search="search_menu"
          >
            <template v-slot:item="{ item }">
              <tr
                @click="activerow(item)"
                :class="{'info black--text': item.id===selectedDepositId}"
              >
                <td>
                  {{ item.title}}
                </td>
                <td v-if="type_of_order==='WALK-IN'">
                  {{ formatPrice(item.amount) }}
                </td>
                <td v-else-if="type_of_order==='FOOD PANDA'">
                  {{ formatPrice(item.amount_food_panda) }}
                </td>
                <td v-else-if="type_of_order==='GRAB FOOD'">
                  {{ formatPrice(item.amount_grab) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" md="8">
          <menu-datas
            :key="this.menus_data.length"
            :menus_data="this.menus_data"
            :is_can_delete="true"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog persistent v-model="is_menu_selected" max-width="500px">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title
          ><h4 class="font-weight-light">ORDER QUANTITY SECTION</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-card-text class="mt-4">
            <v-text-field
              label="How Many Order"
              required
              outlined
              v-model="order_quantity"
              type="number"
              min="0"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <!-- alert -->
              <v-col cols="12" v-show="alert">
                <v-alert
                  color="warning"
                  text
                  class="mb-0"

                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{alert_message}}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  color="primary" class="w-full" @click="save_order">
                  Save Changes
                </v-btn>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  color="error" class="w-full" @click="is_menu_selected=false">
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiAlertOutline, mdiCloudUploadOutline
    , mdiAccountSearchOutline
    , mdiDelete
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import MenuDatas from '@/views/cashier_interface/order_transaction/components/Confirmation'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      order_quantity: '1',
      total_amount: '0.00',

      is_deleting: false,
      is_menu_selected: false,
      selectedDepositId: -1,
      search_menu: '',
      menus_data: [],
      order_selected: {},
      headers: [
        {text: 'MENU', value: 'title', sortable: false},
        {text: 'AMOUNT', value: 'amount', sortable: false},
      ],
    }
  }
  export default {
    props: {
      menus_order: Array,
      order_items: Array,
      type_of_order: String,
    },
    components: {
      MenuDatas,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiDelete,
        },
      }
    },
    data() {
      return initialState()
    },
    async mounted() {
      this.menus_data = this.menus_order
      this.over_all_total()
    },
    watch: {
      menus_data: function () {
        this.$emit('data', {
          menus_data: this.menus_data,
        })
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['user_id', 'branch_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory', ['check_availability_inventory']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      over_all_total() {
        this.total_amount = '0.00'
        var tot = 0
        this.menus_data.forEach(function (item) {
          tot += parseFloat(item.total)
        })
        this.total_amount = this.formatPrice(tot)
      },
      activerow(item) {
        this.order_selected = item
        this.selectedDepositId = item.id
        this.is_menu_selected = true
      },
      save_order() {
        this.alert = false
        if (parseInt(this.order_quantity) <= 0) {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        } else if (this.$refs.form.validate()) {
          this.check_availability_inventory({
            branch_id: this.branch_id,
            menu_order_id: this.selectedDepositId,
          })
            .then(response => {
              if (parseFloat(response.data) > 0) {
                if (parseFloat(this.order_quantity) <= parseFloat(response.data)) {
                  this.is_menu_selected = false
                  var amou=this.type_of_order=== 'FOOD PANDA'?this.order_selected.amount_food_panda:(this.type_of_order=== 'GRAB FOOD'?this.order_selected.amount_grab:this.order_selected.amount);
                  this.menus_data.push({
                    id: 0,
                    menu: this.order_selected,
                    amount:amou ,
                    total: parseFloat(this.order_quantity * amou),
                    type_of_order: this.type_of_order,
                    quantity: this.order_quantity,
                    done: moment().format('YYYY-MM-DD-HH-mm-ss'),
                  })
                  this.over_all_total()
                } else {
                  this.alert = true
                  this.alert_message = this.order_selected.title + ' has ' + response.data + ' quantity'
                  this.saving = false
                }
              } else {
                this.alert = true
                this.alert_message = this.order_selected.title + ' has ' + response.data + ' quantity'
                this.saving = false
              }
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
